import React, { useEffect, useState } from "react";
import { Add, Filter, Loading, Table, Title } from "../../components";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";
import { Col, Row } from "../../Grid-system";
import WorkersFilter from "../../components/Filter/WorkersFilter";
import Popup from "../../components/Popup";
import stillWorking from "../../images/still-working.gif";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Workers = () => {
  const { filter, setFilter } = useFilter();
  const [errorToast, setErrorToast] = useState({});
  const [temp, setTemp] = useState(0);

  const { data, deleteItem, isLoading, successfulDelete } = useFETCH(
    `admin/workers?page=${filter.get("page") ?? 1}
    ${
      filter.get("date")
        ? `&date=${filter.get("date")}&temp=${temp}`
        : `&temp=${temp}`
    }
    ${
      filter.get("cost_center_id")
        ? `&cost_center_id=${filter.get("cost_center_id")}`
        : filter.get("section_id")
        ? `&section_id=${filter.get("section_id")}`
        : ""
    } ${
      filter.get("presented") ? `&presented=${filter.get("presented")}` : ""
    }${filter.get("check_by") ? `&check_by=${filter.get("check_by")}` : ""}`,
    "admin/workers"
  );

  useEffect(() => {
    if (successfulDelete) setTemp((prev) => prev + 1);
  }, [successfulDelete]);

  const { printClick } = useFETCH(`admin/workers`);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const onWorkerClick = (worker) => {
    if (worker.hours.length > 0) {
      setPopupContent(
        <>
          <div className="report-dev">
            <h3
              style={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "20px",
                borderBottom: "2px solid rgb(31 196 213)", // Adjust the color and thickness as needed
              }}
            >
              التقرير المختصر للساعات المُسجلة
            </h3>
            <h4 style={{ textAlign: "center", fontWeight: "600" }}>
              إجمالي الساعات الأساسية : {worker.total_main_hours} .
            </h4>
            <h4 style={{ textAlign: "center", fontWeight: "600" }}>
              إجمالي الساعات الإضافية : {worker.total_extra_hours} .
            </h4>
            <h4 style={{ textAlign: "center", fontWeight: "600" }}>
              إجمالي الساعات :{" "}
              {worker.total_extra_hours + worker.total_main_hours} .
            </h4>
            {filter.get("check_by") && (
              <h4 style={{ textAlign: "center", fontWeight: "600" }}>
                إجمالي الساعات الواجبة :{" "}
                {worker[`${filter.get("check_by")}_hours`]} .
              </h4>
            )}
          </div>
          <h3
            style={{
              marginTop: "7px",
              textAlign: "center",
              fontWeight: "600",
              fontSize: "25px",
              width: "70%",
              marginRight: "auto",
              marginLeft: "auto",
              borderBottom: "2px solid rgb(31 196 213)", // Adjust the color and thickness as needed
            }}
          >
            تقرير الساعات المفصل
          </h3>
          <Table
            thead={[
              "مركز التكلفة",
              "الساعات الأساسية",
              "الساعات الاضافية",
              "تاريخ العمل",
            ]}
            pageCount={data?.data?.data.last_page}
            tbody={["cost_center", "main_hours", "extra_hours", "worked_at"]}
            tdata={worker?.hours}
            view={"workers-popup"}
          />
        </>
      );
      setIsPopupOpen(true);
    } else {
      setErrorToast({
        show: true,
        text: "لا يوجد ساعات عمل مضافة لهذا العامل",
      });
      notify();
    }
  };

  const getDateRangeText = () => {
    const checkBy = filter.get("check_by");
    const section = data.data.section;

    return (
      <>
        <span>
          {"التقرير العام للعمال "}
          {filter.get("presented") == 0
            ? "الغير مسجلين "
            : filter.get("presented") == 1
            ? "المسجلين"
            : filter.get("presented") == 2
            ? "المقصرين"
            : ""}
          {" حسب "}
          {checkBy === "day" ? (
            <>
              {"اليوم بتاريخ : "}
              <span className="span-txt">
                {data.data.date_range.start_date}
              </span>
            </>
          ) : (
            <>
              {checkBy === "week" ? "الأسبوع" : "الشهر"}
              {" من تاريخ : "}
              <span className="span-txt">
                {data.data.date_range.start_date}
              </span>
              {" وحتى تاريخ : "}
              <span className="span-txt">{data.data.date_range.end_date}</span>
            </>
          )}
          {" ."}
        </span>
        {section && (
          <>
            <br />
            <span>
              {" ضمن قسم : "}
              <span className="span-txt">
                {" "}
                {section.name}{" "}
                {filter.get("section_id") && (
                  <img
                    style={{ borderRadius: "100px", display: "inline-block" }}
                    src={stillWorking}
                    width="35"
                  />
                )}
              </span>

              {" ."}
            </span>
          </>
        )}
      </>
    );
  };
  const notify = () => {
    toast.error(errorToast?.text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    <div>
      {<ToastContainer />}
      <Popup
        isOpen={isPopupOpen}
        onClose={togglePopup}
        content={popupContent}
      />{" "}
      {isLoading ? <Loading /> : ""}
      <Row>
        {!filter.get("calender") && (
          <>
            <span className="font-bold">
              عرض تقرير دوام العمال العام حسب :{" "}
            </span>{" "}
            <WorkersFilter />
          </>
        )}
      </Row>
      {data?.data?.date_range?.start_date && (
        <Row>
          <div className="date-range">
            <h5
              style={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              {getDateRangeText()}
            </h5>
          </div>
        </Row>
      )}
      {((filter.get("date") && filter.get("check_by")) ||
        filter.get("calender")) && (
        <Row>
          <Col className="" md={3}>
            <button
              onClick={() => {
                let param = filter.get("cost_center_id")
                  ? "cost_center_id"
                  : filter.get("section_id")
                  ? "section_id"
                  : null;
                setFilter({
                  date: filter.get("date"),
                  page: 1,
                  search: filter.get("search") ? filter.get("search") : "",
                  check_by: filter.get("check_by"),
                  ...(param && { [param]: filter.get(param) }),
                  ...(filter.get("calender") && {
                    calender: filter.get("calender"),
                  }),
                });
              }}
              style={{ backgroundColor: "#055255" }}
              className={` ${
                +!filter.get("presented") ? "opacity-60" : ""
              } w-full  py-3 rounded-2xl  text-white hover:opacity-60`}
            >
              الكل
            </button>
          </Col>
          <Col className="" md={3}>
            <button
              onClick={() => {
                let param = filter.get("cost_center_id")
                  ? "cost_center_id"
                  : filter.get("section_id")
                  ? "section_id"
                  : null;
                setFilter({
                  presented: 1,
                  date: filter.get("date"),
                  page: 1,
                  search: filter.get("search") ? filter.get("search") : "",
                  check_by: filter.get("check_by"),
                  ...(param && { [param]: filter.get(param) }),
                  ...(filter.get("calender") && {
                    calender: filter.get("calender"),
                  }),
                });
              }}
              style={{ backgroundColor: "#02a94b" }}
              className={` ${
                +filter.get("presented") === 1 ? "opacity-60" : ""
              } w-full  py-3 rounded-2xl  text-white hover:opacity-60`}
            >
              المسجلين
            </button>
          </Col>
          {!filter.get("calender") && (
            <Col className="" md={3}>
              <button
                onClick={() => {
                  let param = filter.get("cost_center_id")
                    ? "cost_center_id"
                    : filter.get("section_id")
                    ? "section_id"
                    : null;
                  setFilter({
                    presented: 2,
                    date: filter.get("date"),
                    page: 1,
                    check_by: filter.get("check_by"),
                    search: filter.get("search") ? filter.get("search") : "",
                    ...(param && { [param]: filter.get(param) }),
                  });
                }}
                style={{ backgroundColor: "yellow", color: "red" }}
                className={` ${
                  filter.get("presented") == 2 ? "opacity-60" : ""
                } w-full  py-3 rounded-2xl  text-white hover:opacity-60`}
              >
                المقصرين
              </button>
            </Col>
          )}
          <Col className="" md={3}>
            <button
              onClick={() => {
                let param = filter.get("cost_center_id")
                  ? "cost_center_id"
                  : filter.get("section_id")
                  ? "section_id"
                  : null;
                setFilter({
                  presented: 0,
                  date: filter.get("date"),
                  page: 1,
                  check_by: filter.get("check_by"),
                  search: filter.get("search") ? filter.get("search") : "",
                  ...(param && { [param]: filter.get(param) }),
                  ...(filter.get("calender") && {
                    calender: filter.get("calender"),
                  }),
                });
              }}
              style={{ backgroundColor: "red" }}
              className={` ${
                filter.get("presented") == 0 || filter.get("calender")
                  ? "opacity-60"
                  : ""
              } w-full  py-3 rounded-2xl  text-white hover:opacity-60`}
            >
              الغير مسجلين
            </button>
          </Col>
        </Row>
      )}
      <Title title="العمال" />
      <Table
        idDel={deleteItem}
        print={printClick}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        thead={[
          "اسم ",
          "تكلفة الساعة الانتاجية",
          "الرقم الوظيفي",
          "القسم الحالي",
          "الساعات اليومية المطلوبة",
          "الساعات الأسبوعية المطلوبة",
          "الساعات الشهرية المطلوبة",
          "أيام العمل",
        ]}
        tbody={[
          "name",
          "hour_cost",
          "job_number",
          "section",
          "day_hours",
          "week_hours",
          "month_hours",
          "translated_days",
        ]}
        tdata={data?.data.data.data}
        view="workers"
        links={[
          { name: "تقارير", link: "/workers/reports/" },
          { name: "تعديل", link: "/workers/" },
        ]}
        onItemClick={onWorkerClick}
      />
      <Add link="/workers/add" />
    </div>
  );
};

export default Workers;
